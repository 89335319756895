<template>
  <div>
    <v-card class="pa-10">
      <Loader v-if="loading"/>
      <template v-else>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="8"
          >
            <v-row no-gutters class="mb-8">
              <v-col cols="12">
                <Comments
                  :comments="comments"
                  @comment-saved="saveComment"
                />
              </v-col>
            </v-row>
            <v-form
              ref="addIssueForm"
              v-model="valid"
              autocomplete="chrome-off"
            >
              <v-row no-gutters>
                <v-col cols="12">
                  <label for="title">{{ $t('saveIssue.title') }}</label>
                  <v-text-field
                    v-model="form.title"
                    id="title"
                    outlined
                    required
                    validate-on-blur
                    dense
                    single-line
                    :rules="required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label for="categories">{{ $t('saveIssue.categories') }}</label>
                  <v-select
                    v-model="form.categoryDictionaryId"
                    id="categories"
                    :items="categoriesIssue"
                    item-text="name"
                    item-value="dictionaryId"
                    outlined
                    required
                    validate-on-blur
                    dense
                    single-line
                    :rules="required"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="my-4 text-body-2 text--secondary text--lighten-3">
                  <p class="mb-0">{{ $t('saveIssue.creationDate') }}: {{ form.dateOfRegistration | dateFormat('DD-MM-YYYY HH:mm') }}</p>
                  <p v-if="form.dateOfUpdate" class="mb-0">{{ $t('saveIssue.updateDate') }}: {{ form.dateOfUpdate | dateFormat('DD-MM-YYYY HH:mm') }}</p>
                  <p v-if="form.createdBy" class="mb-0">{{ $t('saveIssue.founded') }}: {{ `${form.createdBy.name} ${form.createdBy.surname}` }}</p>
                  <p class="mb-0">{{ $t('saveIssue.id') }}: {{ form.issueDisplayId }}</p>
                </v-col>
                <v-col cols="12" class="my-4">
                  <v-card>
                    <v-card-title class="text-h5">
                      {{ $t('saveIssue.orderingTitle') }}
                    </v-card-title>
                    <v-card-text class="h-100 d-flex align-end">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <label for="companyName">{{ $t('saveIssue.companyName') }}</label>
                          <v-autocomplete
                            v-model="selectedCompanyOrdering"
                            :items="companies"
                            :loading="companyOrderingLoading"
                            :search-input.sync="companyOrderingSearch"
                            hide-no-data
                            hide-selected
                            item-text="name"
                            return-object
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            :rules="required"
                            @update:search-input="selectedCompanyOrderingSearchInput"
                            @input="selectedCompanyOrderingInput"
                          />
                        </v-col>
                        <v-col cols="12">
                          <label for="companyId">{{ $t('saveIssue.companyId') }}</label>
                          <v-text-field
                            v-model="form.orderingCompany.displayId"
                            id="companyId"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            :rules="required"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center">
                          <div class="w-100 employee-autocomplete">
                            <label for="employee">{{ $t('saveIssue.employee') }}</label>
                            <div class="d-flex">
                              <v-btn v-if="form.orderingCompany.user" class="mr-1" icon @click="startConversationWithUser(form.orderingCompany.user.id)">
                                <v-icon color="info">mdi-chat-outline</v-icon>
                              </v-btn>
                              <v-btn v-else class="mr-1" icon disabled>
                                <v-icon>mdi-chat-outline</v-icon>
                              </v-btn>
                              <v-autocomplete
                                v-model="form.orderingCompany.user"
                                :items="employeesOrdering"
                                id="employee"
                                hide-no-data
                                hide-selected
                                :item-text="displayFullName"
                                item-value="id"
                                outlined
                                required
                                validate-on-blur
                                dense
                                single-line
                                return-object
                                :rules="required"
                              >
                              </v-autocomplete>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <label for="submitDate">{{ $t('saveIssue.submitDate') }}</label>
                              <v-text-field
                                v-model="form.orderingCompany.dateOfIssueRegistration"
                                id="submitDate"
                                append-icon="mdi-calendar"
                                readonly
                                dense
                                outlined
                                single-line
                                :rules="required"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="form.orderingCompany.dateOfIssueRegistration"
                              :first-day-of-week="1"
                              @input="menu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" class="my-4">
                  <v-card>
                    <v-card-title class="text-h5">
                      {{ $t('saveIssue.descriptionTitle') }}
                    </v-card-title>
                    <v-card-text class="h-100 d-flex align-end">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <label for="addDescription">{{ $t('saveIssue.addDescription') }}</label>
                          <v-textarea
                            v-model="form.details.description"
                            id="addDescription"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <label for="executedOrderDate">{{ $t('saveIssue.executedOrderDate') }}</label>
                              <v-text-field
                                v-model="form.details.dateOfOrderExecution"
                                id="executedOrderDate"
                                append-icon="mdi-calendar"
                                readonly
                                dense
                                outlined
                                single-line
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="form.details.dateOfOrderExecution"
                              :first-day-of-week="1"
                              @input="menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12">
                          <label for="orderId">{{ $t('saveIssue.orderId') }}</label>
                          <v-text-field
                            v-model="form.details.orderId"
                            id="orderId"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <label for="conversation">{{ $t('saveIssue.conversation') }}</label>
                          <v-select
                            v-model="form.details.chatContext"
                            id="conversation"
                            :items="chatContextTypes"
                            item-value="id"
                            item-text="description"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <UploadFileButton
                            :items="form.details.documents"
                            :label="$t('saveIssue.addFile')"
                            @remove="uploadFileButtonRemove"
                            @input="uploadFileButtonInput"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" class="my-4">
                  <v-card>
                    <v-card-title class="text-h5">
                      {{ $t('saveIssue.relatedCompanyTitle') }}
                    </v-card-title>
                    <v-card-text class="h-100 d-flex align-end">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <label for="selectedLinkedCompany">{{ $t('saveIssue.companyName') }}</label>
                          <v-autocomplete
                            v-model="selectedLinkedCompany"
                            :items="linkedCompanies"
                            :loading="companyLinkedLoading"
                            :search-input.sync="companyLinkedSearch"
                            hide-no-data
                            hide-selected
                            item-text="name"
                            id="selectedLinkedCompany"
                            return-object
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                            @update:search-input="selectedCompanyLinkedSearchInput"
                            @input="selectedCompanyLinkedInput"
                          />
                        </v-col>
                        <v-col cols="12">
                          <label for="linkedCompanyId">{{ $t('saveIssue.companyId') }}</label>
                          <v-text-field
                            v-model="form.linkedCompany.displayId"
                            id="linkedCompanyId"
                            outlined
                            required
                            validate-on-blur
                            dense
                            single-line
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <label for="linkedCompanyuserId">{{ $t('saveIssue.employee') }}</label>
                          <div class="d-flex">
                            <v-btn v-if="form.linkedCompany.user" class="mr-1" icon @click="startConversationWithUser(form.linkedCompany.user.id)">
                              <v-icon color="info">mdi-chat-outline</v-icon>
                            </v-btn>
                            <v-btn v-else class="mr-1" icon disabled>
                              <v-icon>mdi-chat-outline</v-icon>
                            </v-btn>
                            <v-autocomplete
                              v-model="form.linkedCompany.user"
                              :items="employeesLinked"
                              id="linkedCompanyuserId"
                              hide-no-data
                              hide-selected
                              :item-text="displayFullName"
                              item-value="id"
                              outlined
                              required
                              validate-on-blur
                              dense
                              single-line
                              return-object
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-row no-gutters class="mt-6">
              <v-col cols="12" class="w-100 d-flex justify-end">
                <v-btn
                  color="primary"
                  class="mr-2"
                  :dark="!saveLoading"
                  :disabled="saveLoading"
                  :loading="saveLoading"
                  @click="submit"
                >
                  {{ $t("saveIssue.save") }}
                </v-btn>
                <v-btn
                  v-if="!form.depreciated"
                  color="primary"
                  width="110"
                  :disabled="closeAndRestoreLoading || disabledCloseButton"
                  :loading="closeAndRestoreLoading"
                  @click="closeClick"
                >
                  {{ $t("saveIssue.end") }}
                </v-btn>
                <v-btn
                  v-if="form.depreciated"
                  color="primary"
                  width="110"
                  :disabled="closeAndRestoreLoading || !$permission('IssueRestoration')"
                  :loading="closeAndRestoreLoading"
                  @click="restoreClick"
                >
                  {{ $t("saveIssue.restore") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-6">
              <v-col cols="12" offset-md="4" md="8">
                <ChangeDepartment
                  :value="form.department"
                  :closed="form.depreciated"
                  @department-changed="changeDepartment"
                />
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-6">
              <v-col cols="12" offset-md="4" md="8">
                <ChangeState
                  :issue-state="form.issueState"
                  :companyId="null"
                  :department="form.department"
                  :closed="form.depreciated"
                  @user-resigned="updateState"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </div>
</template>

<script>
import UploadFileButton from '@/components/forms/UploadFileButton'
import Service from '@/services'
import moment from 'moment'
import { departmentEnum, isFile } from '@/utils/helpers'
import Comments from '@/components/comments/Comments'
import Issue from '@/mixins/Issue'
import ChangeState from '@/components/issue/ChangeState'
import Loader from '@/components/Loader'
import ChangeDepartment from '@/components/issue/ChangeDepartment'
import { startConversation } from '@/signalr/chat'

export default {
  name: 'Details',
  components: {
    UploadFileButton,
    Comments,
    ChangeState,
    Loader,
    ChangeDepartment
  },
  mixins: [Issue],
  data () {
    return {
      closeAndRestoreLoading: false,
      currentUserDepartment: null,
      comments: []
    }
  },
  computed: {
    disabledCloseButton () {
      return !this.form.issueState.customerService.currentlyHandledBy ||
        this.form.department !== departmentEnum.customerService ||
        this.form.issueState.customerService.currentlyHandledBy.id !== this.$store.getters.userCommonData.id ||
        !((this.$permission('IssueClose') && this.$store.getters.userCommonData.id === this.form.currentlyHandledBy) || this.$permission('IssueCloseAll'))
    }
  },
  async created () {
    this.loading = true
    await this.fetchData()
    await this.loadDetails()
    await this.fetchComments()
    this.loading = false
  },
  methods: {
    isFile,
    async fetchData () {
      try {
        const { data: categoriesIssue } = await Service.get('Issue/GetIssueCategories')
        this.categoriesIssue = categoriesIssue
      } catch (e) {
      }
    },
    async submit () {
      await this.$refs.addIssueForm.validate()
      if (!this.valid) {
        return
      }
      this.saveLoading = true
      try {
        await Service.post('Issue/UpdateIssue', {
          ...this.form
        })
        await this.loadDetails()
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        await this.$router.push({ name: 'IssuesCustomerService', params: { activeTab: '1' } })
      } catch (e) {
      } finally {
        this.saveLoading = false
      }
    },
    async loadDetails () {
      try {
        const { data } = await Service.get('Issue/GetGeneralIssueDetails', {
          issueId: this.$route.params.id
        })

        this.form = {
          ...this.form,
          ...data
        }

        this.form.orderingCompany.dateOfIssueRegistration = moment(this.form.orderingCompany.dateOfIssueRegistration).format('YYYY-MM-DD')
        await this.selectedCompanyOrderingSearchInput(this.form.orderingCompany.name)
        await this.selectedCompanyOrderingInput(this.companies[0])
        this.selectedCompanyOrdering = this.companies[0]

        if (this.form.linkedCompany.name && this.form.linkedCompany.name.length > 0) {
          await this.selectedCompanyLinkedSearchInput(this.form.linkedCompany.name)
          await this.selectedCompanyLinkedInput(this.linkedCompanies[0])
          this.selectedLinkedCompany = this.linkedCompanies[0]
        }

        if (this.form.details.dateOfOrderExecution) {
          this.form.details.dateOfOrderExecution = moment(this.form.details.dateOfOrderExecution).format('YYYY-MM-DD')
        }
      } catch (e) {
      }
    },
    async uploadFileButtonInput (files) {
      try {
        this.form.details.documents = files
        const newFiles = this.form.details.documents.filter(file => this.isFile(file))
        const formData = new FormData()
        formData.append('issueId', this.$route.params.id)
        for (let i = 0; i < newFiles.length; i++) {
          formData.append(`documents[${i}].fileWrapper.file`, newFiles[i])
          formData.append(`documents[${i}].description`, newFiles[i].name)
        }

        await Service.post('Issue/UploadDocuments', formData)
        const { data: documents } = await Service.get('Issue/GetDocuments', { issueId: this.$route.params.id })
        this.form.details.documents = documents
      } catch (e) {
      }
    },
    async uploadFileButtonRemove (file) {
      try {
        await Service.delete('Issue/DeleteDocument', {
          issueId: this.$route.params.id,
          documentId: file.id
        })
      } catch (e) {
      }
    },
    async closeClick () {
      this.closeAndRestoreLoading = true
      try {
        await Service.put('Issue/CloseGeneralIssue', { issueId: this.$route.params.id })
        await this.loadDetails()
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
      } catch (e) {
      } finally {
        this.closeAndRestoreLoading = false
      }
    },
    async restoreClick () {
      this.closeAndRestoreLoading = true
      try {
        await Service.put('Issue/RestoreGeneralIssue', { issueId: this.$route.params.id })
        await this.loadDetails()
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
      } catch (e) {
      } finally {
        this.closeAndRestoreLoading = false
      }
    },
    async fetchComments () {
      try {
        const { data: comments } = await Service.get('Issue/GetComments', {
          issueId: this.$route.params.id
        })
        this.comments = comments
      } catch (e) {}
    },
    async saveComment (text) {
      try {
        await Service.post('Issue/AddCommentCommand', {
          issueId: this.$route.params.id,
          text
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
        await this.fetchComments()
      } catch (e) {
      }
    },
    changeDepartment (department) {
      this.form.department = department
    },
    async updateState () {
      try {
        const { data: issueState } = await Service.get('Issue/GetIssueState', {
          issueId: this.$route.params.id
        })
        this.form.issueState = issueState
      } catch (e) {}
    },
    startConversationWithUser (id) {
      startConversation(id)
    }
  }
}
</script>
